<template>
    <div class="qr-code-with-overlay" :class="{ 'center': center }" :style="{ height: height + 'px' || 'auto' }">
        <div class="qr-card">
            <e-qr-code class="qr-code" :data="url" :size="180" :image="cmsWebsite.design.favicon.src"/>
        </div>
        <b-img class="scan-overlay" :src="overlayUrl" />
    </div>
</template>

<script>
// qr-code-with-overlay #hot-reload fix
import EQrCode from "../../vue-components/components/e-qr-code.vue";

export default {
    name: `qr-code-with-overlay`,
    components: {EQrCode},
    props: {
        url: { type: String, required: true },
        overlayUrl: { type: String, default: require(`@/assets/img/qr-codes/qr-scan-overlay-inline.webp`) },
        center: Boolean,
        height: String,
    }
}
</script>

<style lang="scss" scoped>
.qr-code-with-overlay {
    --qr-code-width: 350px;
    --qr-code-height: 200px;
    position: relative;
    width: var(--qr-code-width);
    height: var(--qr-code-height);

    .qr-card {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 200px;
        padding: 10px;
        background: white;
        border-radius: 13px;
    }

    .scan-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &.center {
        width: 100%;

        .scan-overlay {
            position: relative;
            object-fit: contain;
        }
        .qr-card {
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            //mix-blend-mode: multiply;
        }
    }
}
</style>
