<template>
    <div class="map-floor-selector" v-if="map && map.floors && map.floors.length > 1">
        <template v-if="!isMobileLayoutOrPreview">
            <b-btn v-for="floor in map.floors" :key="`floor-${floor.id}`" @click="selectFloor(floor)"
                   :variant="floor.id === floorSelected.id ? 'accent': 'white'" class="btn-floor"
                 :class="[{ 'selected': floor.id === floorSelected.id, 'disabled': isDisabled(floor), 'floor-basement': floor.level < 0 }]">
                {{floor.getName()}}

                <span v-if="getFloorClass(floor)" :class="getFloorClass(floor)"/>
            </b-btn>
        </template>

        <b-dropdown v-else variant="white">
            <template #button-content>
                {{floorSelected.getName()}}
            </template>
            <b-dropdown-item v-for="floor in map.floors" :key="`floor-${floor.id}`" @click="selectFloor(floor)"
                   :link-class="{ 'active': floor.id === floorSelected.id }">
                <i :class="[fontAwesomePrefix, mobileFloorIcon(floor)]"/>
                <span>{{floor.getName()}}</span>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
export default {
    name: `map-floor-selector`,
    props: {
        map: {type: Object},
        floorSelected: {type: Object},
        navPath: {type: Object}
    },
    methods: {
        selectFloor(floor) {
            this.$emit(`selected`, floor);
        },
        isDisabled(floor) {
            return this.navPath && this.navPath.startFloor.id !== floor.id && this.navPath.endFloor.id !== floor.id;
        },
        mobileFloorIcon(floor) {
            let icon = ``;
            if (this.navPath && this.navPath.endFloor && this.navPath.endFloor.id === floor.id) {
                icon = `fa-location-dot`;
            } else if (this.navPath && this.navPath.startFloor && this.navPath.startFloor.id === floor.id) {
                icon = `fa-circle-dot`;
            } else if (this.floorSelected.id === floor.id) {
                icon = `fa-circle`;
            }
            return icon;
        },
        getFloorClass(floor) {
            if (!this.navPath)
                return false;

            if (this.navPath.startFloor.id === floor.id) {
                if (this.navPath.floorDirection === `same-floor`) {
                    return `direction-same-floor`;
                } else {
                    return `floor-nav-path path-start floor-direction-${this.navPath.floorDirection}`;
                }
            }

            if (this.navPath.endFloor.id === floor.id)
                return `floor-nav-path path-end floor-direction-${this.navPath.floorDirection}`;

            if (Math.min(this.navPath.startFloor.level, this.navPath.endFloor.level) < floor.level && floor.level < Math.max(this.navPath.startFloor.level, this.navPath.endFloor.level)) {
                return `floor-nav-path path-between`;
            } else {
                return ``;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.map-floor-selector {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    text-align: right;
    display: grid;

    --floor-btn-height: 40px;
    --floor-btn-margin: 5px;
    --floor-path-width: 8px;
    --floor-path-height: calc(var(--floor-btn-height) + 2 * var(--floor-btn-margin));
    --floor-half-height: calc(var(--floor-btn-height) / 2 + var(--floor-btn-margin) + var(--floor-path-width));
    --floor-path-arrow-width: 12px;

    .btn-floor {
        display: block;
        margin-bottom: var(--floor-btn-margin);
        position: relative;
    }

    // btn and dropdown button
    .btn, ::v-deep .btn {
        &.disabled {
            opacity: 1;
            color: rgba(black, 0.25) !important;
        }

        //border: 1px solid rgba(black, 0.1);
        box-shadow: 0 3px 8px rgba(black, 0.2);
    }

    .direction-same-floor {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        --floor-path-arrow-width: 8px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: calc(var(--floor-btn-height) / 2 - var(--floor-path-arrow-width));
            border: var(--floor-path-arrow-width) solid transparent;
        }

        &:before {
            left: calc(-1 * var(--floor-path-arrow-width) - var(--floor-btn-margin));
            border-left-color: var(--map-primary-color);
        }

        &:after {
            left: calc(100% - var(--floor-path-arrow-width) + var(--floor-btn-margin));
            border-right-color: var(--map-primary-color);
        }
    }

    // horizontal line
    .floor-nav-path {
        position: absolute;
        width: 15px;
        height: 8px;
        right: calc(100% + 10px);
        top: 14px;
        display: block;

        &:not(.path-between) {
            background: var(--map-primary-color);
        }

        // Before is vertical line
        &:before {
            content: "";
            position: absolute;
            height: var(--floor-path-height);
            right: 15px;
            background: var(--map-primary-color);
            width: var(--floor-path-width);
            top: -20px;
        }

        &.path-between:before {

        }

        //&.path-start:not(.floor-direction-same-floor):before,
        //&.path-end:before {
        //    --floor-path-height: calc(var(--floor-path-height) / 2 + var(--floor-path-width));
        //}

        &.path-start.floor-direction-up:before,
        &.path-end.floor-direction-down:before {
            height: var(--floor-half-height);
            top: calc(-1 * var(--floor-half-height) + var(--floor-path-width));
        }

        &.path-start.floor-direction-down:before,
        &.path-end.floor-direction-up:before {
            height: var(--floor-half-height);
            top: 0;
            //top: calc(var(--floor-half-height) + var(--floor-path-width));
        }

        // After are for arrows shapes
        &.floor-nav-path:after {
            content: "";
            position: absolute;
            right: -5px;
            top: -8px;
        }

        &.path-start:not(.floor-direction-same-floor):after {
            border: var(--floor-path-arrow-width) solid transparent;
            border-right-color: var(--map-primary-color);
        }

        &.path-end:after {
            border: var(--floor-path-arrow-width) solid transparent;
            border-left-color: var(--map-primary-color);
            margin-right: calc(-1 * var(--floor-path-arrow-width));
        }
    }
}

.layout-mobile {
    .map-floor-selector {
        top: calc(var(--map-top-padding) + 10px);

        ::v-deep .dropdown {
            li + li .dropdown-item {
                border-top: 1px solid rgba(black, 0.1);
            }

            .fa-location-dot {
                font-size: 17pt;
            }
        }
    }
}

.layout-kiosk {
    .map-floor-selector {
        left: auto;
        top: auto;
        right: 20px;
        bottom: 40px;
    }
}
</style>
