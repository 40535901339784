<template>
    <component :is="componentType" ref="container" class="map-assistant" @close="close" :class="{ 'fullscreen': fullscreen }"
               lock-in-parent :draggable="false" drag-header-only :resizable="false" :expanded.sync="mobileCardVisible"
               :no-footer="isKioskDevice || display !== 'list'" :no-close="!isKioskDevice" :top-margin="5" :reduced-height="155" no-body>
        <!-- draggable:false for e-panel desktop and tablet, drag-header-only: true for e-mobile-card for mobile -->
        <template #header>
            <template v-if="isKioskDevice">
                <b-img class="destination" :src="previewImage"/>
                <div class="d-flex align-items-start justify-content-start w-100" v-touch:swipe="directorySwiped">
                    <h2 class="panel-title">{{tr(`Path to ${destination.getFullName()}|Chemin vers ${destination.getFullName()}`)}}</h2>
                </div>

                <b-row class="my-3">
                    <b-col cols="3">
                        <b-card class="stat" no-body>
                            <b-card-body class="px-0 py-2 text-dark">
                                <span v-tr>Distance</span>
                                <span class="ml-2 text-primary f-700">{{navPath.distance|translateMeterDistance}}</span>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col cols="3">
                        <b-card class="stat" no-body>
                            <b-card-body class="px-0 py-2 text-dark">
                                <span v-tr>Duration|Durée</span>
                                <span class="ml-2 text-primary f-700">{{navPath.duration}}min</span>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col cols="6">
                        <b-btn variant="accent" class="w-100 send-to-phone-btn" @click="qrInstructionModal = true">
                            <b-img :src="require('@/assets/icons/wayfinding/send-to-phone.webp')" width="60"/>
                            <div class="text p3" v-tr>Send to your phone|Envoyer sur votre tél.</div>
                        </b-btn>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-btn v-if="display === 'steps'" variant="translucent-light" class="mr-2" @click="setDisplay('list')" style="margin-left: -10px;">
                    <i :class="[fontAwesomePrefix, 'fa-chevron-left']"/>
                </b-btn>

                <div class="text-left">
                    <div class="f-title f-11 f-600" v-tr>Path to|Chemin vers</div>
                    <h4 class="panel-title f-700">{{destination.getFullName()}}</h4>
                </div>

                <b-btn v-if="isMobileLayout" variant="translucent-light" class="ml-auto" @click="toggleCard">
                    <i :class="[fontAwesomePrefix, display === 'end-navigation' ? 'fa-close' : mobileCardVisible ? 'fa-chevron-down' : 'fa-chevron-up']"/>
                </b-btn>
            </template>
        </template>

        <template #close v-if="isKioskDevice">
            <b-btn v-if="isWheelchairMode" variant="white" @click="$emit(`update:wheelchairExtended`, !wheelchairExtended)" class="ml-auto text-dark btn-lg" style="position: absolute; top: 10px; right: 80px; height: 56px;">
                {{tr(wheelchairExtended ? 'Hide|Masquer' : 'Details|Détails')}}
                <i class="fas ml-1" :class="wheelchairExtended ? 'fa-chevron-down' : 'fa-chevron-up'"/>
            </b-btn>
            <b-btn variant="white" @click="close()" class="ml-auto text-dark btn-lg" style="position: absolute; top: 10px; right: 10px;">
                <i class="fas fa-close" style="font-size: 24pt;"/>
            </b-btn>
        </template>

        <b-row class="main-instruction-container">
            <b-col class="d-flex flex-column h-100 pb-3">
                <template v-if="display === 'list'">
                    <b-card class="mb-2 p-2" style="border: 1px solid var(--muted-color);" v-if="isDesktopDevice" no-body>
                        <b-row>
                            <b-col cols="4" class="px-2">
                                <e-qr-code class="qr-code" :data="`${baseSharingUrl}&medium=sharing&source=user`" :size="130" :margins="0" :image="cmsWebsite.design.favicon.src"/>
                            </b-col>
                            <b-col cols="8" class="text-left d-flex flex-column justify-content-center">
                                <div class="p6 text-left">
                                    <span v-tr>Scan the QR code with your phone to receive step by step instructions|
                                        Numérisez le code QR avec votre téléphone pour recevoir les instructions</span>
                                </div>
                                <b-btn class="mt-1" size="sm" variant="accent" @click="shareLink">
                                    <span v-tr>Send to your phone|Envoyer au téléphone</span>
                                    <i class="fa-share ml-1" :class="fontAwesomePrefix"/>
                                </b-btn>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card no-body header-bg-variant="primary" class="overflow-auto">
                        <b-card-header class="py-2 bg-primary" v-if="isKioskDevice">
<!--                            <b-btn variant="accent" @click="start" class="float-right">-->
<!--                                <i class="fas fa-camera mr-2"/>-->
<!--                                <span v-tr>Image View|Trajet en images</span>-->
<!--                            </b-btn>-->

                            <h3 class="text-white" v-tr>Instructions|Instructions</h3>
                        </b-card-header>

                        <template v-if="previewImage">
                            <template v-if="!isKioskDevice">
                                <b-img class="w-100" height="300" :src="previewImage" style="object-fit: cover"/>
                            </template>
                        </template>

                        <b-list-group class="instruction-list">
                            <template v-if="!isKioskDevice">
                                <b-list-group-item class="justify-content-center py-2 bg-light border-top" style="border-radius: 0">
                                    <span v-tr>Distance</span>
                                    <span class="ml-2 text-primary f-700">{{navPath.distance|translateMeterDistance}}</span>
                                    <span class="mx-3">|</span>
                                    <span v-tr>Duration|Durée</span>
                                    <span class="ml-2 text-primary f-700">{{navPath.duration}}min</span>
                                </b-list-group-item>
                            </template>

                            <b-list-group-item v-for="(instruction, index) in navPath.instructions" @click="selectStep(index)" :key="'instr-' + index" class="interactive">
                                <span class="list-instruction-badge">
                                    <span v-if="index === 0">
                                        <i class="fas fa-street-view"/>
                                    </span>
                                    <span v-else-if="instruction.direction === 'arrived'">
                                        <i class="fas fa-flag"/>
                                    </span>
                                    <span v-else-if="instruction.icon">
                                        <i :class="[fontAwesomePrefix, instruction.icon]"/>
                                    </span>
                                </span>
                                <div class="list-instruction-text">
                                    <b-badge v-if="index === 0" class="mb-1 mr-1 text-accent" variant="light">
                                        <i class="fa-dot-circle mr-1" :class="fontAwesomePrefix"/>
                                        {{navPath.start.getName()}}
                                    </b-badge>

                                    <div>
                                        <span v-if="instruction.showDistance && instruction.nextInstruction?.distance > 0">
                                            {{instruction.action}} {{instruction.nextInstruction.distance|translateMeterDistance}}
                                        </span>
                                        {{instruction.description}}

                                        <b>{{instruction.nextLocation}}</b>

                                        <div>
                                            <b-badge v-if="instruction.instructionGlyph" variant="warning" class="ml-2 text-white">
                                                <i :class="[fontAwesomePrefix, instruction.instructionGlyph]"/>
                                            </b-badge>
                                            <span v-if="instruction.instructions">
                                                {{instruction.instructions}}
                                            </span>
                                        </div>
                                    </div>
                                    <b-badge class="mt-1 text-accent" variant="light"  v-if="index === navPath.instructions.length - 1">
                                        <i class="fa-map-pin mr-1" :class="fontAwesomePrefix"/>
                                        <span v-tr>Arrival|Arrivée</span>
                                    </b-badge>
                                </div>

                                <i v-if="index === 0 && isKioskDevice" class="fas fa-fw fa-hand-back-point-up ml-auto mr-2 breathe-animation"/>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card>
                </template>

                <template v-else-if="display === 'end-navigation'">
                    <div class="instruction-step text-center">
                        <b-card no-body>
                            <div class="bg-primary p-3 text-white text-center">
                                <i class="fa-face-smile-wink mb-2 fa-3x" :class="fontAwesomePrefix" style="opacity: 0.5"/>
                                <div class="mb-2" v-tr>
                                    Thank you for using our Virtual Guide|
                                    Merci d'avoir utilisé notre accompagnateur virtuel
                                </div>

                                <hr>

                                <b-form>
                                    <b-form-group :label="tr('How was your experience?|Comment a été votre expérience?')">
                                        <b-form-rating v-model="rating" variant="warning" inline/>
                                    </b-form-group>

                                    <b-form-group :label="`Comment (optional)|Commentaire (facultatif)`|tr">
                                        <b-textarea v-model="feedbackMessage" no-resize :placeholder="tr('Let us know what we could improve|Dites-nous ce que vous aimeriez améliorer')"/>
                                    </b-form-group>

                                    <b-btn @click="sendFeedback" variant="accent" :disabled="!this.rating">
                                        <span v-tr>Send feedback|Envoyer l'évaluation</span>
                                    </b-btn>
                                    <div class="mt-3">
                                        <b-btn @click="close(); $emit(`terminated`)" variant="transparent-light" v-tr>Close|Fermer</b-btn>
                                    </div>
                                </b-form>
                            </div>
                        </b-card>
                    </div>
                </template>
                <template v-else>
                    <div class="instruction-step text-center">
                        <b-card no-body>
                            <template v-if="isKioskDevice">
                                <b-card-header class="py-2 bg-primary text-center">
                                    <h3 class="text-white w-100" v-tr>
                                        <template v-if="index === 0">{{ tr('Start in this direction|Partez dans cette direction') }}</template>
                                        <template v-else>{{ tr('Step by step|Étape par étape') }}</template>
                                    </h3>
                                </b-card-header>
                            </template>

                            <div>
                                <pano-360 v-if="currentInstruction.image360" ref="pano" :src="currentInstruction.image360" :img-angle="currentInstruction.offsetAngle" :mode-ar.sync="modeAR" :fullscreen.sync="fullscreen" class="instruction-pano"
                                          :from-angle="currentInstruction.fromAngle" :to-angle="currentInstruction.toAngle" :is-arrived="isArrived" :is-first-step="isFirstStepWithPano"/>
                                <div v-else class="instruction-container">
                                    <b-img fluid :blank="!currentInstruction.image" blank-color="#ccc" :src="currentInstruction.image" class="instruction-photo" rounded/>
                                    <map-destination-reached v-if="isArrived && !fullscreen"/>
                                </div>
                                <b-btn v-if="!isKioskDevice && !fullscreen" class="btn-toggle-fullscreen" variant="transparent" @click="toggleFullscreen">
                                    <i class="fa-fw" :class="[fontAwesomePrefix, fullscreen ? 'fa-minimize' : 'fa-maximize']"/>
                                </b-btn>
                            </div>

                            <div class="nav-controls-container">
                                <div class="nav-controls d-flex p-3 f-14">
                                    <b-btn variant="white" :size="isKioskDevice ? 'lg' : 'md'" @click="setIndex(index - 1)" :disabled="index === 0" style="flex: 2;">
                                        <i class="fa-chevron-left mr-2" :class="fontAwesomePrefix"/>
                                        <span v-tr>Previous|Précédent</span>
                                    </b-btn>

                                    <div class="step d-flex flex-column text-white mx-2">
                                        <div class="f-10">{{tr('Step|Étape')}}</div>
                                        <div>{{(index + 1)}} / {{navPath.instructions.length}}</div>
                                    </div>

                                    <template v-if="isArrived && isMobileLayout">
                                        <b-btn variant="accent" size="md" class="ml-2" @click="endNavigation" style="flex: 2;">
                                            <span>{{tr('End|Terminer')}}</span>
                                            <i class="fa-chevron-right ml-2" :class="fontAwesomePrefix"/>
                                        </b-btn>
                                    </template>
                                    <template v-else>
                                        <b-btn variant="white" :size="isKioskDevice ? 'lg' : 'md'" class="ml-2" @click="setIndex(index + 1)" :disabled="isArrived" style="flex: 2;">
                                            <span>{{tr('Next|Suivant')}}</span>
                                            <i class="fa-chevron-right ml-2" :class="fontAwesomePrefix"/>
                                        </b-btn>
                                    </template>
                                </div>
                            </div>

                            <div class="nav-directions" :class="{'bg-primary text-white': isArrived && fullscreen}" @click="onNavDirectionsClicked">
                                <b-btn v-if="fullscreen" class="btn-toggle-fullscreen text-dark" size="sm" @click="toggleFullscreen()" variant="light" style="position: absolute;top: -3px;right: -3px;">
                                    <i class="fas" :class="[fontAwesomePrefix, isMobileLayout ? 'fa-close' : 'fa-minimize']"/>
                                </b-btn>
                                <div class="nav-directions-container d-flex w-100 align-items-center">
                                    <div class="direction-icon">
                                        <i :class="[fontAwesomePrefix, currentInstruction.icon]"/>
                                    </div>
                                    <div class="nav-directions-text">
                                        <div v-if="currentInstruction.index === 0 || currentInstruction.currentLocation">
                                            <b-badge variant="light" class="distance mb-1 md text-ellipsis text-dark" style="max-width: 100%;">
                                                <i class="fa-location-dot mr-1" :class="fontAwesomePrefix"/>
                                                <template v-if="currentInstruction.index === 0">
                                                    <span>{{'Let\'s Start|Départ'|tr}}</span>
                                                </template>
                                                <template v-else>
                                                    <span>{{currentInstruction.currentLocation}}</span>
                                                </template>
                                            </b-badge>
                                        </div>

                                        <span v-if="currentInstruction.showDistance && currentInstruction.nextInstruction?.distance > 0">
                                            <span>{{currentInstruction.action}}</span> {{currentInstruction.nextInstruction.distance|translateMeterDistance}}
                                        </span>
                                        {{currentInstruction.description}}

                                        <b class="text-accent ml-1" v-if="currentInstruction.nextLocation">{{currentInstruction.nextLocation}}</b>

                                        <div>
                                            <b-badge v-if="currentInstruction.instructionGlyph" variant="warning" class="ml-2 text-white">
                                                <i :class="[fontAwesomePrefix, currentInstruction.instructionGlyph]"/>
                                            </b-badge>
                                            <span v-if="currentInstruction.instructions">
                                                {{currentInstruction.instructions}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="nav-options" v-if="!isKioskDevice">
                                <b-btn class="p-1 text-underline" variant="link" size="sm" @click="errorReport.visible = true" v-tr>
                                    Report an error in this step|Signaler une erreur à cette étape
                                </b-btn>

                                <b-btn variant="light" class="ml-2" @click="toggleTextToSpeech" :size="isMobileLayout ? 'sm': 'md'" style="position: absolute; right: 5px; bottom: 5px;">
                                    <i class="fas" :class="muted ? 'fa-volume-xmark' : 'fa-volume-high' "/>
                                </b-btn>
                            </div>
                        </b-card>
                    </div>
                </template>
            </b-col>

            <b-col v-if="isKioskDevice">
                <b-card class="mb-4" body-class="px-0" @click="qrInstructionModal = true" no-body>
                    <b-card-header class="py-2 bg-primary text-center">
                        <h3 class="text-white" v-tr>Open on your phone|Ouvrir sur votre téléphone</h3>
                    </b-card-header>

                    <b-card-body class="bg-secondary py-4">
                        <qr-code-with-overlay class="mx-auto" :url="`${baseSharingUrl}&medium=kiosk-to-phone&source=qr-code`"/>

                        <div class="p3 my-3 text-white" v-tr>
                            Scan the QR code with your phone to receive step by step instructions|
                            Numérisez le code QR avec votre téléphone pour recevoir les instructions
                        </div>

                        <b-btn class="my-2" variant="white" size="lg" @click="qrInstructionModal = true">
                            <i class="fa-info-circle mr-2" :class="fontAwesomePrefix"/>
                            <span v-tr>How to use a QR code?|Comment utiliser un code QR ?</span>
                        </b-btn>
                    </b-card-body>
                </b-card>

                <b-card v-if="display === 'steps'" @click="setDisplay('list')" no-body>
                    <b-card-body class="d-flex flex-row align-items-center justify-content-start" style="height: 209px; border: 2px solid var(--success); background: linear-gradient(180deg, #ffffff, #e3f3e4); border-radius: inherit">
                        <b-img width="100" :src="require('@/assets/icons/wayfinding/icon-directions.svg')"/>
                        <div class="ml-2 my-2 pl-2 text-left">
                            <div class="p1 mb-2 text-success" v-tr>Step List|Liste des étapes</div>
                            <div v-tr>Display the list of instructions to find your way to your destination|Afficher la liste des instructions pour aller à votre destination</div>
                        </div>
                    </b-card-body>
                </b-card>

                <b-card v-else @click="setDisplay('steps')" no-body>
                    <b-card-body class="d-flex flex-row align-items-center justify-content-start" style="height: 209px; border: 2px solid var(--accent-color); background: linear-gradient(180deg, #ffffff, #e3ebf3); border-radius: inherit">
                        <b-img width="100" :src="require('@/assets/icons/wayfinding/icon-ar-view.svg')"/>
                        <div class="ml-2 my-2 pl-2 text-left">
                            <div class="p1 mb-2 text-accent" v-tr>Image View|Trajet en images</div>
                            <div v-tr>Display directions step by step visually|Afficher les directions à suivre étapes par étape visuellement</div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="isKioskDevice && !isWheelchairMode" class="bg-white" style="height: 150px;">
            <b-col cols="12" class="py-4">
                <div class="text-dark p2" v-tr>Tip: you can take a photo of this screen to remember the directions|Astuce : prenez cet écran en photo pour vous souvenir de votre trajet</div>
                <div class="text-muted mt-4" v-tr>Powered by Eye-In Media www.eyeinmedia.com|Propulsé par Eye-In Média www.eyeinmedia.com</div>
            </b-col>
        </b-row>

        <template #footer>
            <div class="w-100 text-auto d-flex justify-content-center">
                <b-btn class="mr-2 px-2" @click="close">
                    <span v-tr>Cancel|Annuler</span>
                </b-btn>
                <b-btn class="mr-2 px-2" @click="shareLink">
                    <template v-if="!isMobileLayout">
                        <span class="mr-1" v-tr>Share|Partager</span>
                    </template>
                    <i class="fa-share-from-square fa-fw" :class="fontAwesomePrefix"/>
                </b-btn>
<!--                <b-dropdown v-if="!isKioskDevice" class="mr-2" toggle-class="px-3" no-caret>-->
<!--                    <template #button-content>-->
<!--                        <template v-if="!isMobileLayout">-->
<!--                            <span class="mr-1" v-tr>Share|Partager</span>-->
<!--                        </template>-->
<!--                        <i class="fa-share-from-square fa-fw" :class="fontAwesomePrefix"/>-->
<!--                    </template>-->
<!--                    <b-dropdown-item @click="shareLink">-->
<!--                        <i class="fa-share" :class="fontAwesomePrefix"/>-->
<!--                        <span v-tr>Share Link|Partager le lien</span>-->
<!--                    </b-dropdown-item>-->
<!--                </b-dropdown>-->
                <b-btn variant="accent" @click="start" style="flex: 5;">
                    <span v-tr>Begin|Commencer</span>
                </b-btn>
            </div>
        </template>

        <modal-send-to-phone v-if="isKioskDevice" :visible.sync="qrInstructionModal" :base-sharing-url="baseSharingUrl" :nav-path="navPath" :analytics-route="analyticsRoute"/>

        <b-modal :title="'Report Feedback|Envoyer des commentaires'|tr" v-model="errorReport.visible" scrollable>
            <b-form>
                <div class="text-center">
                    <i class="fa-triangle-exclamation text-warning fa-2x mb-2" :class="fontAwesomePrefix" style="opacity: 0.75"/>
                </div>
                <b-form-group :label="`Subject|Sujet`|tr">
                    <b-select v-model="errorReport.issue" required>
                        <option value="unclear-instructions" v-tr>Instructions are not clear|Les instructions ne sont pas claires</option>
                        <option value="image-obsolete" v-tr>Image is not up to date|L'image n'est plus à jour</option>
                        <option value="image-wrong" v-tr>Wrong or incorrect image|Mauvaise image ou image qui ne correspond pas</option>
                        <option value="image-missing" v-tr>Missing Image|Image manquante</option>
                        <option value="missing-instructions" v-tr>Missing important instruction|Il manque des instructions importantes</option>
                        <option value="under-construction" v-tr>Under Construction|Travaux en cours</option>
                        <option value="location-moved" v-tr>This location has been moved somewhere else|Cet emplacement a été déplacé ailleurs</option>
                        <option value="signage-changed" v-tr>Signage has changed|La signalétique a changé</option>
                        <option value="cannot-go-to-next" v-tr>Can't go to next step|Ne peut pas aller à l'étape suivante</option>
                        <option value="cannot-end-navigation" v-tr>Can't terminate navigation|Ne peut pas terminer la navigation</option>
                        <option value="improvement" v-tr>Suggestion of improvement|Suggestion ou amélioration</option>
                        <option value="other" v-tr>Other|Autre</option>
                        <option value="test" v-tr>Test Only|Test seulement</option>
                    </b-select>
                </b-form-group>
                <b-form-group :label="`Comment|Commentaire`|tr">
                    <b-textarea v-model="errorReport.message" :placeholder="tr('Add a comment or a description|Ajouter un commentaire ou une description')" no-resize/>
                </b-form-group>
                <b-checkbox v-model="errorReport.contactMe">
                    <span v-tr>Allow to be contacted later about this issue (optional)|Autoriser à me contacter plus tard à propos de ce problème (optionnel)</span>
                </b-checkbox>
                <template v-if="errorReport.contactMe">
                    <b-form-group :label="`Email|Courriel`|tr">
                        <b-input-group>
                            <b-input-group-text><i class="fa-envelope" :class="fontAwesomePrefix"/></b-input-group-text>
                            <e-input type="email" v-model="errorReport.email" placeholder="Ex: john.doe@email.com "/>
                        </b-input-group>
                    </b-form-group>
                </template>
            </b-form>
            <template #modal-footer>
                <b-btn @click="errorReport.visible = false" v-tr>Cancel|Annuler</b-btn>
                <b-btn variant="primary" @click="sendErrorReport" :disabled="!errorReport.issue" v-tr>Send report|Envoyer le rapport</b-btn>
            </template>
        </b-modal>
    </component>
</template>

<script>
import MapDestinationReached from "@/components/maps/map-destination-reached.vue";
import ModalSendToPhone from "@/components/maps/modal-send-to-phone.vue";
import QrCodeWithOverlay from "@/components/qr-code-with-overlay.vue";
import MapMixin from "@/helpers/maps/map-mixin.js";
import {swal} from "vue-swal";
import EInput from "../../../vue-components/components/e-input.vue";
import EPanel from "../../../vue-components/components/e-panel.vue";
import EQrCode from "../../../vue-components/components/e-qr-code.vue";
import NetworkV2 from "../../../vue-components/helpers/NetworkV2.js";
import {translateMeterDistance} from "../../../vue-components/helpers/vue-filters.js";
import Pano360 from "../pano-360.vue";
import EMobileCard from "../../../vue-components/components/e-mobile-card.vue";
import Feedback from "../../../common/db/models/core/Feedback.js";
import {BFormRating} from "bootstrap-vue";

// map-assistant #hot-reload-debug
export default {
    name: `map-assistant`,
    components: {ModalSendToPhone, EInput, QrCodeWithOverlay, BFormRating, MapDestinationReached, Pano360, EMobileCard, EQrCode, EPanel},
    mixins: [MapMixin],
    props: {
        navPath: {type: Object, required: true},
        analyticsRoute: {type: Object, required: true},
        wheelchairExtended: {type: Boolean}
    },
    data() {
        return {
            display: `list`,
            index: 0,
            currentInstruction: null,
            fullscreen: false,
            modeAR: false,
            muted: !this.isKioskDevice,
            mobileCardVisible: true,
            speechSynthesis: null,
            speechVoices: [],
            deviceVoice: null,
            errorReport: {
                visible: false,
                issue: ``,
                message: ``,
                contactMe: false,
                email: ``
            },
            feedbackMessage: ``,
            rating: null,
            qrInstructionModal: false,
            feedbackSent: false
        }
    },
    computed: {
        componentType() {
            return this.isMobileLayoutOrPreview ? `e-mobile-card` : `e-panel`;
        },
        isArrived() {
            return this.index === this.navPath.instructions.length - 1;
        },
        baseSharingUrl() {
            let baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            return `${baseUrl}?${this.tr(`start|depart`)}=${this.navPath.start.getPermalink()}&${this.tr(`end|arrivee`)}=${this.destination.getPermalink()}`;
        },
        destination() {
            return this.navPath.end;
        },
        previewImage() {
            // if there is no end image on the map, take the one from CMS instead
            if (this.navPath.previewImage) {
                return this.navPath.previewImage;
            } else if (this.destination.cmsItem) {
                return this.destination.cmsItem.getPreviewImage();
            } else {
                return null;
            }
        },
        isFirstStepWithPano() {
            return this.navPath.instructions.findIndex(instruction => instruction.image360) === this.index;
        }
    },
    activated() {
        this.setIndex(0);
        this.feedbackSent = false;
        this.errorReport.visible = false;
        this.errorReport.message = ``;
        this.errorReport.type = ``;
        this.feedbackMessage = ``;
        this.rating = null;
    },
    mounted() {
        this.setIndex(0);

        this.speechSynthesis = window.speechSynthesis;
        const voices = this.speechSynthesis?.getVoices()

        if (voices.length !== 0) {
            this.speechVoices = [...voices];
            this.deviceVoice = this.getVoice();
        } else {
            speechSynthesis.onvoiceschanged = () => {
                this.speechVoices = [...this.speechSynthesis.getVoices()];
                this.deviceVoice = this.getVoice();
            }
        }
        if (this.isKioskDevice) {
            this.start();
        }
    },
    methods: {
        start() {
            this.setDisplay(`steps`);
            this.setIndex(0);
            this.playTextToSpeech();
            this.$emit(`start-navigation`);
        },
        endNavigation() {
            this.setDisplay(`end-navigation`);
            if (this.isMobileLayout) {
                this.$emit(`end-navigation`);
            }
        },
        getVoice() {
            const lang = this.getLanguage();
            const langTag = lang === `fr` ? `fr-CA` : `en-CA`;

            const preferredVoices = {
                'en-CA': [`Samantha`, `Microsoft Clara`, `Google US English`],
                'fr-CA': [`Amelie`, `Microsoft Antoine Online (Natural) - French (Canada)`, `Google français`]
            };

            const preferredVoiceNames = preferredVoices[langTag];

            let selectedVoice = null;
            for (const voiceName of preferredVoiceNames) {
                selectedVoice = this.speechVoices.find(voice => voice.name.startsWith(voiceName) && voice.lang.startsWith(lang));
                if (selectedVoice) break;
            }

            if (selectedVoice) {
                return selectedVoice;
            } else {
                console.warn(`No preferred voice found for`, langTag);
                return null;
            }
        },
        selectStep(step) {
            this.setDisplay(`steps`);
            this.setIndex(step);
            this.playTextToSpeech();
        },
        setDisplay(display) {
            this.display = display;
            if (this.display === `list`) {
                this.$emit(`set-instruction`, null);
            } else if (this.display === `end-navigation`) {
                this.$emit(`set-instruction`, null);
            } else if (this.display === `steps`) {
                if (this.isMobileLayout) {
                    this.fullscreen = true;
                }
            }
        },
        setIndex(index, pathChanged = false) {
            const playSound = index !== this.index || pathChanged;
            this.index = Math.max(Math.min(index, this.navPath.instructions.length - 1), 0);
            this.currentInstruction = this.navPath.instructions[this.index];
            if (playSound) {
                this.playTextToSpeech();
            }
            if (this.display === `steps`) {
                this.$emit(`set-instruction`, this.currentInstruction);
            } else {
                this.$emit(`set-instruction`, null);
            }
        },
        toggleTextToSpeech() {
            this.muted = !this.muted;
            this.muted && this.speechSynthesis.cancel();
            this.playTextToSpeech();
        },
        playTextToSpeech() {
            if (this.muted || this.display === `list`) {
                return;
            }
            const langTag = this.getLanguage() === `fr` ? `fr-CA` : `en-CA`
            const speech = new SpeechSynthesisUtterance();
            speech.voice = this.deviceVoice;
            speech.voiceURI = `native`;
            speech.lang = langTag;
            speech.text = this.currentInstruction.getTextToSpeech();

            if (this.index === 0) {
                speech.text = this.tr(`Let's go to|Allons à`) + ` ${this.navPath.end.getFullName()}. ${speech.text}`;
            }
            this.speechSynthesis.cancel();
            this.speechSynthesis.speak(speech);
        },
        toggleCard() {
            if (this.display === `end-navigation`) {
                this.close();
            } else {
                this.$refs.container.toggleCard();
            }
        },
        close() {
            this.$emit(`close`);
            this.$emit(`set-instruction`, null);
        },
        async sendErrorReport() {
            this.showLoading();
            const errorFeedback = new Feedback({
                project: `cms-editor`,
                type: `error`,
                dealerid: this.dealerid,
                message: this.errorReport.message,
                issue: this.errorReport.issue,
                contactMe: this.errorReport.contactMe,
                email: this.errorReport.email,
                data: this.currentInstruction
            });

            await NetworkV2.post(`/api/public/feedback/save`, errorFeedback);
            this.errorReport.message = ``;
            this.errorReport.visible = false;
            swal({
                title: this.tr(`Thank you|Merci`),
                text: this.tr(`Your Feedback is very valuable for us and allow us to improve our services!|Vos retours sont appréciés et nous aident à améliorer notre service !`),
                icon: `success`
            });
        },
        async sendFeedback() {
            this.feedbackSent = true;

            this.showLoading();

            const feedback = new Feedback({
                project: `cms-editor`,
                type: `rating`,
                dealerid: this.dealerid,
                rating: this.rating,
                message: this.feedbackMessage,
                data: this.navPath.instructions
            });

            await NetworkV2.post(`/api/public/feedback/save`, feedback);
            swal({
                title: this.tr(`Thank you|Merci`),
                text: this.tr(`Your Feedback has been sent successfully|Vos retours ont bien été envoyés`),
                icon: `success`
            });
            this.close();
            this.$emit(`reset-navigation`);
        },
        toggleFullscreen() {
            if (this.isMobileLayout) {
                this.setDisplay(this.fullscreen ? `list` : `steps`);
                this.fullscreen = !this.fullscreen;
            } else {
                this.fullscreen = !this.fullscreen;
                if (this.$refs.pano) {
                    this.$nextTick(() => {
                        this.$refs.pano.updateContainerSize();
                    });
                }
            }
        },
        onNavDirectionsClicked() {
            if (this.isKioskDevice) {
                this.setDisplay(`list`);
            }
        },
        directorySwiped(direction) {
            if (!this.isWheelchairMode)
                return;

            if (direction === `top`) {
                this.$emit(`update:wheelchairExtended`, true);
            } else if (direction === `bottom`) {
                this.$emit(`update:wheelchairExtended`, false);
            }
        },
        shareLink() {
            const itinerary = `${this.navPath.start.getName()} ${this.tr(`to|vers`)} ${this.navPath.end.getName()}`;
            const metaTitle = itinerary + ` - ` + this.tr(this.cmsWebsite.meta.title);
            const metaDescription = this.tr(this.cmsWebsite.meta.description);

            if (navigator.clipboard) {
                navigator.clipboard.writeText(`${this.baseSharingUrl}&medium=sharing&source=user`)
                    .then(() => {
                        this.$bvToast.toast(this.tr(`Link copied to clipboard|Lien copié dans le presse-papier`), {
                            title: this.tr(`Link copied|Lien copié`),
                            variant: `info`,
                            solid: true,
                            toaster: `b-toaster-bottom-right`
                        });
                    });
            }

            if (navigator.share) {
                navigator.share({
                    title: metaTitle,
                    text: metaDescription,
                    url: `${this.baseSharingUrl}&medium=sharing&source=user`
                }).then(() => {
                    console.log(`Link shared`);
                }).catch(console.error);
            }

            NetworkV2.post(`/api/public/analytics/sharing/add`, {
                ...this.analyticsRoute,
                language: this.language,
                url: `${this.baseSharingUrl}&medium=sharing&source=user`
            });
        }
    },
    watch: {
        navPath() {
            this.setIndex(0, true);
        }
    }
}
</script>

<style lang="scss" scoped>
.map-assistant {
    display: flex;
    flex-direction: column;
    --instruction-image-width: 380px;
    --instruction-image-height: 300px;
    --nav-instructions-max-width: 500px;

    ::v-deep .panel-body {
        overflow: auto;
        height: 100%;
    }

    .list-group {
        border-radius: 10px;
        background: var(--lightest-color);
        text-align: left;
        font-weight: 500;

        .list-group-item {
            padding: 0;
            clear: left;
            display: flex;
            align-items: center;
            border: none;

            .list-instruction-badge {
                display: inline-block;
                border-radius: 50%;
                min-width: 42px;
                max-width: 42px;
                min-height: 42px;
                max-height: 42px;
                float: left;
                text-align: center;
                background: var(--light-color);
                line-height: 42px;
                vertical-align: middle;
                margin: 5px 0 5px 10px;
            }

            .list-instruction-text {
                padding: 5px 10px;
            }

            & + .list-group-item {
                border-top: 1px solid var(--border-color);
            }
        }
    }

    .nav-controls-container {
        background: #222 !important;
    }

    .instruction-container {
        position: relative;
    }

    .instruction-container,
    .instruction-photo {
        width: var(--instruction-image-width);
        height: var(--instruction-image-height);
        object-fit: cover;
    }

    .instruction-step {
        .card-header {
            display: flex;
        }

        .distance {
            font-size: 1rem;
        }

        .nav-directions {
            font-weight: 500;
            min-height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 10px;
        }

        .direction-icon {
            float: left;
            margin-right: 10px;
            min-width: 60px;
            max-width: 60px;
            line-height: 60px;
            border-radius: 50%;
            background: var(--accent-color);
            color: white;
            display: block;
            text-align: center;
            font-size: 35px;
        }

        .nav-directions-text {
            text-align: left;
            overflow: hidden;
        }

        .nav-options {
            padding: 10px 20px;
        }

        .btn.btn-toggle-fullscreen {
            position: absolute;
            right: 5px;
            top: 5px;
            //width: 140px;
            backdrop-filter: blur(5px) contrast(0.8);
            background-color: rgba(white, 0.8);

            &.active {
                background-color: var(--primary-color);
            }
        }
    }

    &.mobile-card {
        margin: 0;
        width: 100% !important;
        //left: 0 !important;
        //bottom: 0;
        //top: 5px;
        //padding-bottom: env(safe-area-inset-bottom);
        //transform: none !important;
        display: flex;
        flex-direction: column;
        z-index: 1050;
        padding-top: 15px;

        --instruction-image-height: 275px;
        --instruction-image-width: 100%;

        ::v-deep .grip {
            height: 15px;
            background: var(--accent-color);

            &:after {
                background: rgba(white, 0.7);
            }
        }

        ::v-deep .card-header {
            border-bottom: 1px solid var(--border-color);
            background: var(--accent-color);
            color: white;
        }

        ::v-deep .mobile-card-body {
            flex: 1;
            max-height: 100%;
            overflow: hidden auto;
        }

        .instruction-step {
            ::v-deep .card {
                ::v-deep .card-body {
                    padding: 5px;
                }
                .nav-directions {
                    font-weight: 500;
                    min-height: 80px;
                }
            }
        }

        .card {
            border-radius: 0;
        }
    }

    &.e-panel {
        margin: 0 !important;
        top: calc(var(--page-padding-top) + 120px) !important;
        height: calc(100vh - var(--menu-height) - 120px) !important;
        left: var(--page-padding-left) !important;
        width: var(--map-assistant-width) !important;
        transform: none !important;
        border-radius: 0;
        box-shadow: none;

        ::v-deep .panel-header {
            background: #227af1;
            color: white;
            //padding: 10px;

            .header-actions {
                position: initial;
            }

            img {
                border-radius: 6px;
            }
        }
    }

    &.fullscreen {
        transform: none !important;
        position: fixed;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        width: auto !important;
        max-width: initial !important;
        height: auto !important;
        max-height: initial !important;
        margin: 0 !important;

        .instruction-container {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 70px;
            left: 0;
            width: 100%;
            height: calc(var(--app-height) - 120px);// 120px height for controls
            z-index: 1;

            .instruction-photo {
                width: inherit;
                height: inherit;
            }
        }

        .nav-directions {
            position: fixed;
            top: 5px;
            left: calc(50% - var(--nav-instructions-max-width) / 2);
            right: 5px;
            width: var(--nav-instructions-max-width);
            z-index: 1000;
            background: white;
            border-radius: 10px;
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
        }

        .nav-controls-container {
            position: fixed;
            bottom: 40px;
            height: 80px;
            width: 100%;
            left: 0;
            z-index: 1000;
            display: flex;
            align-items: center;
            justify-content: center;

            .nav-controls {
                width: var(--nav-instructions-max-width);
            }
        }

        .nav-options {
            position: fixed;
            bottom: 0;
            height: 40px;
            width: 100%;
            left: 0;
            z-index: 1000;
            background: #222;
            padding-top: 0;

            .btn-link {
                color: white;
            }
        }
    }

    // change light btn bootstrap focus bg color too close to the disable one
    .btn.btn-white:focus, .btn.btn-white:active {
        background: var(--white-color);
    }
}

.layout-mobile {
    .map-assistant {
        .nav-directions {
            top: 5px;
            left: 5px;
            right: 5px;
            width: auto;
        }
    }
}

.layout-kiosk {
    .map-assistant {
        z-index: 100 !important;
        margin: 0 !important;
        top: var(--kiosk-map-height) !important;
        left: var(--page-padding-left) !important;
        transform: none !important;
        right: var(--page-padding-right) !important;
        width: auto !important;
        height: calc(100vh - var(--kiosk-map-height)) !important;
        border-radius: 0 !important;
        background: linear-gradient(180deg, #f1f5f9, #e2f5f9) !important;

        --instruction-image-width: 470px;
        --instruction-image-height: 460px;

        ::v-deep .panel-header {
            background: #3d454a;
            color: white;
            padding: 15px;
            min-height: 185px;
            max-height: 185px;
            display: flex;
            align-items: flex-start;
            justify-content: left;
            flex-direction: column;
            padding-left: 310px;

            > .destination {
                object-fit: cover;
                position: absolute;
                left: 10px;
                top: -97px;
                border: 6px solid #3d454a;
                border-radius: 12px;
                width: 280px;
                height: 280px;
            }

            .panel-title {
                font-size: 25pt;
                height: 80px;
                max-width: 580px;
                display: block;
                text-overflow: ellipsis;
                text-align: left;
                overflow: hidden;
            }

            .send-to-phone-btn {
                border: 2px solid #ffffff40;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                align-items: center;
                height: 50px;
                top: -5px;
                position: relative;
            }

            .send-to-phone-btn img {
                float: left;
                position: relative;
                left: 5px;
                margin-right: 15px;
                top: -12px;
            }

            .send-to-phone-btn .text {
                text-align: left;
                color: white;
            }

            .send-to-phone-btn:focus,
            .send-to-phone-btn:active {
                background-color: var(--accent-color);
            }
        }

        ::v-deep .panel-body {
            padding: 15px 15px 0;

            .row {
                margin-left: -15px !important;
                margin-right: -15px !important;
                width: auto;

                .col, [class^=col-] {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }

        .main-instruction-container {
            // leave space for dead zone
            height: calc(100% - 150px);
        }

        .instruction-list {
            overflow: auto;
        }
    }
}

.accessibility-wheelchair {
    .map-assistant {
        bottom: 0;
        top: auto !important;
        position: absolute;
        //margin-left: 10px !important;
        //margin-right: 10px !important;
        height: calc(var(--wheelchair-reduced-height) + 35px) !important;

        ::v-deep .panel-header {
            padding-left: 250px;

            .panel-title {
                max-width: 500px;
                font-size: 20pt;
            }

            .destination {
                width: 210px;
                height: 210px;
                top: -80px;
            }
        }

        &.wheelchair-extended {
            top: 70px !important;
            height: var(--kiosk-map-height) !important;
            z-index: 10;
            box-shadow: 0 -5px 15px rgba(black, 0.3);

            .destination {
                top: -26px;
            }
        }

        .main-instruction-container {
            height: 100%;
        }
    }
}
</style>
