<template>
    <div ref="qrCode"></div>
</template>

<script>
import QRCodeStyling from "qr-code-styling";

export default {
    name: `e-qr-code`,
    props: {
        size: { type: Number, default: 300 },
        margins: { type: Number, default: 3 },
        data: { type: String, required: true },
        image: { type: String },
        color: { type: String, default: `#000` },
        backgroundColor: { type: String, default: `#fff` }
    },
    data() {
        return {
            qrCode: null
        }
    },
    async created() {
        await this.$nextTick();
        this.generateQrCode();
    },
    methods: {
        generateQrCode(forDownload = false) {
            this.qrCode = new QRCodeStyling({
                width: forDownload ? 500 : this.size,
                height: forDownload ? 500 : this.size,
                type: `svg`,
                data: this.data,
                image: this.image,
                margin: this.margins,
                dotsOptions: {
                    color: this.color,
                    type: `extra-rounded`
                },
                backgroundOptions: {
                    color: this.backgroundColor
                },
                imageOptions: {
                    crossOrigin: `anonymous`,
                    margin: 3
                },
                cornersSquareOptions: {
                    type: `extra-rounded`
                }
            });

            QRCodeStyling._clearContainer(this.$refs.qrCode);
            this.qrCode.append(this.$refs.qrCode);
        },
        async downloadQrCode() {
            if (this.qrCode) {
                this.generateQrCode(true);
                await this.qrCode.download({
                    name: `qr-code`,
                    extension: `png`
                });
                this.generateQrCode();
            }
        }
    }
}
</script>

<style scoped>

</style>
